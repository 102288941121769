<template>
  <g>
    <svg:style>
      .cls-1 {
        isolation: isolate;
      }

      .cls-10, .cls-11, .cls-2 {
        stroke: #aeaeae;
      }

      .cls-10, .cls-11, .cls-2, .cls-3 {
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#BG-gradient);
      }

      .cls-3 {
        fill: none;
        stroke: #6a6a6a;
      }

      .cls-4 {
        opacity: 0.5;
        mix-blend-mode: multiply;
      }

      .cls-5 {
        fill: url(#radial-gradient);
      }

      .cls-6 {
        fill: url(#Bottom_Gradient_2);
      }

      .cls-7 {
        fill: url(#LR_Gradient_2);
      }

      .cls-8 {
        fill: url(#LR_Gradient_2-2);
      }

      .cls-9 {
        fill: url(#Top_Gradient_2);
      }

      .cls-10 {
        fill: url(#glass-pattern);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient
      id="Bottom_Gradient_2"
      data-name="Bottom Gradient 2"
      :x1="doorLeftWidth1 + 1745.42"
      :y1="doorTopHeight1 + 13781.81"
      :x2="doorLeftWidth1 + 1745.42"
      :y2="doorTopHeight1 + 13773.28"
      gradientTransform="translate(1815.26 14046.77) rotate(180)"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff" stop-opacity="0.05"/>
      <stop offset="0.05" stop-color="#010204" stop-opacity="0.5"/>
      <stop offset="0.06" stop-color="#010204" stop-opacity="0.5"/>
      <stop offset="0.58" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="0.87" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="0.89" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="1" stop-color="#010204" stop-opacity="0.6"/>
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2"
      data-name="LR Gradient 2"
      :x1="doorLeftWidth1 + 7845.65"
      :y1="doorTopHeight1 + 6920.99"
      :x2="doorLeftWidth1 + 7845.65"
      :y2="doorTopHeight1 + 6912.63"
      gradientTransform="translate(-6890.81 8071.48) rotate(-90)"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff" stop-opacity="0.3"/>
      <stop offset="0.01" stop-color="#e9e9e9" stop-opacity="0.3"/>
      <stop offset="0.02" stop-color="#ababac" stop-opacity="0.3"/>
      <stop offset="0.03" stop-color="#454647" stop-opacity="0.3"/>
      <stop offset="0.04" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="0.58" stop-color="#010204" stop-opacity="0.1"/>
      <stop offset="0.89" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="1" stop-color="#010204" stop-opacity="0.6"/>
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2-2"
      :x1="doorLeftWidth1 + 109.48"
      :y1="doorTopHeight1 + 225.94"
      :x2="doorLeftWidth1 + 117.86"
      :y2="doorTopHeight1 + 225.94"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="Top_Gradient_2"
      data-name="Top Gradient 2"
      :x1="doorLeftWidth1 + 69.93"
      :y1="doorTopHeight1 + 186.72"
      :x2="doorLeftWidth1 + 69.93"
      :y2="doorTopHeight1 + 178.17"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff" stop-opacity="0.6"/>
      <stop offset="0" stop-color="#fff" stop-opacity="0.56"/>
      <stop offset="0" stop-color="#fff" stop-opacity="0.44"/>
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.33"/>
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.25"/>
      <stop offset="0.02" stop-color="#fff" stop-opacity="0.18"/>
      <stop offset="0.03" stop-color="#fff" stop-opacity="0.13"/>
      <stop offset="0.04" stop-color="#fff" stop-opacity="0.11"/>
      <stop offset="0.06" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.26" stop-color="#fff" stop-opacity="0.04"/>
      <stop offset="0.47" stop-color="#fff" stop-opacity="0"/>
      <stop offset="0.87" stop-color="#010204" stop-opacity="0.2"/>
      <stop offset="1" stop-color="#010204" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient
      id="glass-gradient"
      :x1="doorLeftWidth1 + 42.83"
      :y1="doorTopHeight1 + 166.34"
      :x2="doorLeftWidth1 + 42.83"
      :y2="doorTopHeight1 + 20.68"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f5"/>
      <stop offset="0.5" stop-color="#dde7e6"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient
      id="glass-gradient-2"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 97.24"
      :y1="doorTopHeight1 + 166.34"
      :x2="doorLeftWidth1 + 97.24"
      :y2="doorTopHeight1 + 20.68"
      xlink:href="#glass-gradient"/>
    <g id="C20">
      <g id="Panels">
        <g id="Panel">
          <polygon
            id="Shadow"
            class="cls-6"
            :points="`${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 109.48} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 117.86} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96}`"/>
          <polygon
            id="Panel_L"
            data-name="Panel L"
            class="cls-7"
            :points="`${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 186.72} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 178.17} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 186.72}`"/>
          <polygon
            id="Panel_R"
            data-name="Panel R"
            class="cls-8"
            :points="`${doorLeftWidth1 + 109.5} ${doorTopHeight1 + 186.93} ${doorLeftWidth1 + 109.48} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 117.86} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 117.86} ${doorTopHeight1 + 178.38} ${doorLeftWidth1 + 109.5} ${doorTopHeight1 + 186.93}`"/>
          <polygon
            id="Highlight"
            class="cls-9" 
            :points="`${doorLeftWidth1 + 109.7} ${doorTopHeight1 + 186.72} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 186.72} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 178.17} ${doorLeftWidth1 + 118.05} ${doorTopHeight1 + 178.17} ${doorLeftWidth1 + 109.7} ${doorTopHeight1 + 186.72}`"/>
        </g>
      </g>
      <rect
        id="glass"
        class="cls-10"
        :x="doorLeftWidth1 + 21.93"
        :y="doorTopHeight1 + 20.68"
        width="41.8"
        height="145.66"/>
      <rect
        id="glass-2"
        data-name="glass"
        class="cls-11"
        :x="doorLeftWidth1 + 76.58"
        :y="doorTopHeight1 + 20.68"
        width="41.32"
        height="145.66"/>
      <polyline
        id="impost"
        class="cls-3"
        :points="`${doorLeftWidth1 + 117.81} ${doorTopHeight1 + 166.34} ${doorLeftWidth1 + 21.8} ${doorTopHeight1 + 166.34} ${doorLeftWidth1 + 21.8} ${doorTopHeight1 + 178.17} ${doorLeftWidth1 + 117.81} ${doorTopHeight1 + 178.17} ${doorLeftWidth1 + 117.81} ${doorTopHeight1 + 166.34}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
